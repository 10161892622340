import { UniSupportApiCS } from '@unikey/unikey-commons/release/csupp'
import { UniIdentityApiID } from '@unikey/unikey-commons/release/identity'

export const partnerKey: string = 'idv';
export const environment: string = 'staging';

const commNonceEndpoint = { nonceEndpoint: 'https://idv-stg-dealer-api.unikey.com/v2/nonces' };
export const supportApi: UniSupportApiCS = new UniSupportApiCS('https://idv-stg-support-api.unikey.com', 'api/v1', 'IDV', { noNonce: true, enableLogging: true, ...commNonceEndpoint });
export const identityApi: UniIdentityApiID = new UniIdentityApiID('https://identity-stg.unikey.com', 'api/v1', 'ec389e9e-8541-45fa-84fd-1ae88f125743', 'Wygxsdpw4OtI/k76sK8Imbkyel1tLg7qHY+saoxs/1FqvvtIl+UT4X+VU8gnmx9t1Vj6Q3Zl4+8slmEKbiKhPA==', { enableLogging: false, ...commNonceEndpoint });

export const oidcScope: string = 'openid email profile comm.support.api identity.api offline_access';
export const oidcClientId: string = 'ec389e9e-8541-45fa-84fd-1ae88f125743';
export const oidcAuthority: string = 'https://identity-stg.unikey.com/.well-known/openid-configuration';
export const oidcRedirectUri: string = 'https://idv-stg-support.unikey.com/#/token';
export const oidcRegisterUri: string = '';
export const oidcClientSecret: string = 'Wygxsdpw4OtI/k76sK8Imbkyel1tLg7qHY+saoxs/1FqvvtIl+UT4X+VU8gnmx9t1Vj6Q3Zl4+8slmEKbiKhPA==';
export const oidcPartnerBrandId: string = '2d751f71-1db9-4958-a6e0-df58d096dfec';
export const oidcPartnerBrandCode: string = 'IDV';
export const oidcSilentRedirectUri: string = 'https://idv-stg-support.unikey.com/#/renew';
export const oidcForgotPasswordRedirect: string = '';

export const termsOfServiceDocument: string = '/legal/termsofuse';
export const privacyPolicyDocument: string = '/legal/privacypolicy';
export const partnerSupportLink: string = 'https://support.identiv.com/';

export const appInsightsInstrumentationKey: string = 'eae17a07-b566-4864-ba8f-b6c9ae2613a0';
export const mockAuth: string = '';

export const buildId: string = '36429';
export const releaseId: string = '6208';
export const cspVersion: string = '3.10.0';
export const commonsVersion: string = '8.0.0-pre-1226';

export const desiredLocales: string = 'en,es,ja,fr,pt';
